import React from 'react';
import { string, arrayOf, shape } from 'prop-types';

import { loadable } from 'nordic/lazy';

const MainHeader = ({ h1, linkH1, subtitle, values, totalResults }) => {
  const HeaderMainMobile = loadable(async () =>
    import(/* webpackChunkName: "chunk-header-main-mobile" */ './main-header.mobile'),
  );

  return h1 ? (
    <HeaderMainMobile h1={h1} linkH1={linkH1} subtitle={subtitle} values={values} totalResults={totalResults} />
  ) : null;
};

MainHeader.propTypes = {
  h1: string,
  linkH1: string,
  subtitle: string,
  totalResults: shape({
    type: string,
    text: string,
  }),
  values: arrayOf(
    shape({
      key: string,
    }),
  ),
};

export default MainHeader;
export { MainHeader };
